<template>
    <component  :is="tag" :noFade="noFade" :large="true" id="create" @save="onSubmit()" @close="$emit('close')">

        <template v-slot:header>
            <span>{{ header }}</span>
        </template>

        <span v-if="ready">
            <div class="edit-article">
                <div class="progress-bar">
                    <div v-if="dateSettings.active" class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('date')" :class="{active: (components[currentStep] === 'date'), filled: form.date}">Datum</div>
                        <div class="article-selection-result"><span v-if="form.date">{{ customFormatter(form.date, false) }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('chest')" :class="{active: (components[currentStep] === 'chest'), filled: form.chest_id}">Fust</div>
                        <div class="article-selection-result"><span v-if="form.chest_id">{{ ccst.chests.filter(chest => {return chest.id == form.chest_id;})[0].name }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('type')" :class="{active: (components[currentStep] === 'type'), filled: form.type_id}">Type</div>
                        <div class="article-selection-result"><span v-if="form.type_id">{{ ccst.types.filter(type => {return type.id == form.type_id;})[0].translate('name') }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('size')" :class="{active: (components[currentStep] === 'size'), filled: form.size_id}">Sortering</div>
                        <div class="article-selection-result"><span v-if="form.size_id">{{ ccst.sizes.filter(size => {return size.id === form.size_id})[0].translate('name') }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('content')" :class="{active: (components[currentStep] === 'content'), filled: form.content_id}">Inhoud</div>
                        <div class="article-selection-result"><span v-if="form.content_id">{{ ccst.contents.filter(content => {return content.id === form.content_id})[0].name }}</span></div>
                    </div>
                    <div v-if="locations !== undefined" class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('location')" :class="{active: (components[currentStep] === 'location'), filled: form.location_id}">Locatie</div>
                        <div class="article-selection-result"><span v-if="form.location_id">{{ locations.filter(location => {return location.id === form.location_id})[0].name }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('amount')" :class="{active: (components[currentStep] === 'amount'), filled: form[amountAs] > 0}">Aantal</div>
                        <div class="article-selection-result"><span v-if="form[amountAs]">{{ form[amountAs] }}</span></div>
                    </div>
                </div>
                <form @submit.prevent enctype="multipart/form-data">

                    <div class="modal-date-input" v-if="dateSettings.active" v-show="components[currentStep] === 'date'">
                        <VueDatePicker
                            inline
                            auto-apply
                            v-model="form.date"
                            :enable-time-picker="false"
                        ></VueDatePicker>
                    </div>

                    <create-harvest-option
                        v-show="components[currentStep] === 'chest'"
                        :options="ccst.chests"
                        v-model="form.chest_id"
                        @chosen="currentStep++"
                        name="chest"
                        :value="form.chest_id"
                    ></create-harvest-option>

                    <create-harvest-option
                        v-show="components[currentStep] === 'type'"
                        :options="ccst.types"
                        v-model="form.type_id"
                        @chosen="currentStep++"
                        name="type"
                    ></create-harvest-option>

                    <create-harvest-option
                        v-show="components[currentStep] === 'size'"
                        :options="ccst.sizes"
                        v-model="form.size_id"
                        @chosen="currentStep++"
                        name="size"
                    ></create-harvest-option>

                    <create-harvest-option
                        v-show="components[currentStep] === 'content'"
                        :options="ccst.contents"
                        v-model="form.content_id"
                        @chosen="currentStep++"
                        name="content"
                    ></create-harvest-option>

                    <create-harvest-option
                        v-if="locations !== undefined"
                        v-show="components[currentStep] === 'location'"
                        :options="locations"
                        v-model="form.location_id"
                        @chosen="currentStep++"
                        name="location"
                    ></create-harvest-option>

                    <div v-show="components[currentStep] === 'amount'" class="create-step">
                        <div class="harvest-assignment-amount">
                            <numpad-input v-model="form[amountAs]"></numpad-input>
                        </div>
                    </div>
                </form>
            </div>
        </span>

        <template v-if="includeDelete" v-slot:before-buttons>
            <button class="modal-button alert" @click="$emit('delete')" type="button">Verwijderen</button>
        </template>
    </component>
</template>

<script>
    import moment from 'moment';
    import Form from '../../helpers/form.js';
    import numpadInput from '../numpad-input.vue';
    import createHarvestOption from './_create-harvest-option.vue';
    import { useCcstStore } from '../../stores/ccst';
    import { mapState } from 'pinia';
    import { shallowRef } from 'vue';
    import SideModal from '../../pages/HarvestAssignment/SideModal.vue'

    export default{
        props: {
            'sideModal': Boolean,

            'header': String,

            'product': Object,

            'locations': Array,
            'locationId': [String, Number], 'date': [String, Object], 'start': [String, Number], 'amount': [String, Number],

            'includeDelete': {type: Boolean, default: false},

            'step': [String, Number],

            'amountAs': {
                type: String,
                default: 'amount'
            },

            'noFade': {
                type: Boolean,
                default: false
            }
        },

        components: {numpadInput, createHarvestOption},

        computed: mapState(useCcstStore, ['chests', 'contents', 'sizes', 'types', 'promise']),

        data() {
            return {
                tag: ((this.sideModal === undefined || ! this.sideModal) ? 'Modal' : shallowRef(SideModal)),

                form: null,

                ready: false,

                dateSettings: {
                    active: false,
                    value: moment(),
                    disabledDates: {},
                    inline: true
                },

                ccst: {chests: [], contents: [], sizes: [], types: []},

                old_chests_active: false,
                old_contents_active: false,
                old_sizes_active: false,
                old_types_active: false,

                components: ['date', 'chest', 'type', 'size', 'content', 'location', 'amount'],

                currentStep: (this.step !== undefined ? this.step : 1)
            }
        },

        methods: {
            createForm() {
                const formData = {
                    date: new Date(),
                    location_id: (this.product !== undefined && this.product.locationId ? this.product.locationId : (this.locationId ? parseInt(this.locationId) : null)),
                    [this.amountAs]: this.amount !== undefined ? this.amount : '',
                    content_id: null,
                    size_id: null,
                    type_id: null,
                    chest_id: null,
                }

                if (this.product !== undefined) {
                    formData['content_id'] = this.product[('contentId' in this.product ? 'contentId' : 'content_id')];
                    formData['size_id'] = this.product[('sizeId' in this.product ? 'sizeId' : 'size_id')];
                    formData['type_id'] = this.product[('typeId' in this.product ? 'typeId' : 'type_id')];
                    formData['chest_id'] = this.product[('chestId' in this.product ? 'chestId' : 'chest_id')];
                    formData['location_id'] = this.product[('locationId' in this.product ? 'locationId' : 'location_id')]
                        ? this.product[('locationId' in this.product ? 'locationId' : 'location_id')]
                        : (this.locationId ? parseInt(this.locationId) : null);

                    formData[this.amountAs] = this.amountAs in this.product ? this.product[this.amountAs] : 0;
                }

                this.form = new Form(formData);
            },

            setDateSettings() {
                this.dateSettings.active = true;

                if (this.date === true) {
                    return;
                }

                if (this.date.disabledDates) {
                    this.dateSettings.disabledDates = this.date.disabledDates;
                }

                if (this.date.inline) {
                    this.dateSettings.inline = this.date.inline;
                }

                if (this.date.date) {
                    if (this.date.date instanceof moment) {
                        this.form.date = this.date.date.toDate();
                    } else {
                        this.form.date = this.date.date;
                    }
                } else {
                    this.form.date = moment();
                }
            },

            dateChanged()
            {

            },

            customFormatter(date, withWeek = true) {
                return moment(date).format('DD-MM-YYYY') + (withWeek ? ' week ' + moment(date).format('W') : '');
            },

            setStep(name) {
                this.currentStep = this.components.findIndex(c => c === name);
            },

            async onSubmit() {
                this.$emit('submitted', this.form);
                this.$emit('close');
                document.querySelector('body').classList = '';
            },

            async close() {
                this.$emit('close');
                document.querySelector('body').classList = '';
            }
        },

        created() {
            this.createForm();

            if (this.date !== undefined) {
                this.setDateSettings();
            }

            if (this.product) {
                this.form.add('id', this.product.id)
            } else if(this.form.hasOwnProperty('id')) {
                this.form.remove('id');
            }

            if (this.locations === undefined) {
                this.components = ['chest', 'type', 'size', 'content', 'amount'];
            }

            if (this.date !== undefined) {
                this.components = ['date', ...this.components];
                if (this.currentStep === 0) {
                    this.currentStep++;
                }
            }

            if (this.start !== undefined) {
                this.setStep(this.start)
            }

            if (
                this.chests === undefined || this.contents === undefined
                || this.sizes === undefined || this.types === undefined
            ) {
                this.promise.then(() => {
                    this.ccst['chests'] = this.backupChests;
                    this.ccst['contents'] = this.backupContents;
                    this.ccst['sizes'] = this.backupSizes;
                    this.ccst['types'] = this.backupTypes;
                    this.ready = true;
                });
            } else {
                this.ccst['chests'] = this.chests;
                this.ccst['contents'] = this.contents;
                this.ccst['sizes'] = this.sizes;
                this.ccst['types'] = this.types;
                this.ready = true;
            }
        },
    };
</script>
