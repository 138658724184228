
<template>
    <td style="width: 100px; border-right-color: #999;">
        #{{ rowNumber }}
    </td>
    <td>
        <select v-model="measurement.mildewTuber">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.mildewLeaves">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.miteTuber">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.miteLeaves">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.yellowGermLeaves">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.yellowLeaves">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.blackTuber">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <select v-model="measurement.diseaseLeaves">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
    </td>
    <td>
        <input type="number" min="0" step="0.1" v-model="measurement.tuberWeight">
    </td>
    <td>
        <input type="number" min="0" step="0.1" v-model="measurement.tuberDiameter">
    </td>
    <td>
        <input type="number" min="0" step="0.1" v-model="measurement.tuberHeight">
    </td>
    <td>
        <input type="number" min="0" v-model="measurement.leavesAmountOfLeaves">
    </td>
    <td>
        <input type="number" min="0" step="0.1" v-model="measurement.leavesWeight">
    </td>
    <td>
        <input type="number" min="0" step="0.1" v-model="measurement.leavesLength">
    </td>
    <td class="icon-holder edit-icons">
        <a class="success-color" :class="{disabled: ! storable}">
            <Icon name="check" @click="store()" />
        </a>
        <a class="alert-color">
            <Icon @click="$emit('cancel')" name="x-mark" />
        </a>
    </td>
</template>

<script setup>
    import ProductionControlMeasurementService from '@/services/http/production-control-measurement-service';
    import { useToast } from 'vue-toast-notification';
    import { computed } from 'vue';
    const emit = defineEmits(['saved', 'updateUpdating', 'cancel']);
    const props = defineProps(['measurement', 'rowNumber', 'updating']);


    const store = function() {
        if (props.updating || ! storable.value) return;
        emit('updateUpdating', true);

        const data  = {
            mildew_tuber: props.measurement.mildewTuber,
            mildew_leaves: props.measurement.mildewLeaves,
            mite_tuber: props.measurement.miteTuber,
            mite_leaves: props.measurement.miteLeaves,
            yellow_germ_leaves: props.measurement.yellowGermLeaves,
            yellow_leaves: props.measurement.yellowLeaves,
            black_tuber: props.measurement.blackTuber,
            disease_leaves: props.measurement.diseaseLeaves,
            tuber_weight: props.measurement.tuberWeight,
            tuber_diameter: props.measurement.tuberDiameter,
            tuber_height: props.measurement.tuberHeight,
            leaves_amount_of_leaves: props.measurement.leavesAmountOfLeaves,
            leaves_weight: props.measurement.leavesWeight,
            leaves_length: props.measurement.leavesLength,
        };

        if (props.measurement.id) {
            ProductionControlMeasurementService.edit(props.measurement.id, data).then(() => {
                emit('saved');
                useToast().success('Production control measurement updated');
            });
        } else {
            data['production_control_id'] = props.measurement.productionControlId;
            ProductionControlMeasurementService.create(data).then(() => {
                emit('saved');
                useToast().success('Production control measurement stored');
            });
        }
    }

    const storable = computed(() => {
        const attributes = [
            'mildewTuber', 'mildewLeaves', 'miteTuber',
            'miteLeaves', 'yellowGermLeaves', 'yellowLeaves',
            'blackTuber', 'diseaseLeaves', 'tuberWeight',
            'tuberDiameter', 'tuberHeight', 'leavesAmountOfLeaves',
            'leavesWeight', 'leavesLength'
        ];

        for (const attribute of attributes) {
            const value = props.measurement[attribute];
            if (value === null || value === '' || value === undefined) {
                return false;
            }
        }

        return true;
    });

</script>
