<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="tasks">
                    <div class="page-header">
                        <h1>Taken</h1>
                        <fab-button @click.native="create = (! create)" icon="plus"></fab-button>
                    </div>

                    <div class="widget-holder">
                        <table class="styled-table">
                            <tr class="styled-table-row" v-for="task of tasks" :key="task.id">
                                <td>{{ task.name }}</td>
                                <td class="styled-table-row-icons">
                                    <a @click="edit = task;"><Icon name="pencil"/></a>
                                    <a class="alert-color" @click="confirm.active = true; confirm.id = task.id">
                                        <Icon name="x-mark"/>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Create v-if="create" @close="create = false" @saved="setTask" />
        <Edit v-if="edit" @close="edit = false" :task="edit" />
        <ConfirmModal v-if="confirm.active" body="Weet je zeker dat je deze taak wilt verwijderen?" @yes="remove" @no="confirm.active = ! confirm.active" />
    </div>
</template>

<script>
    import ConfirmModal from './../../../components/ConfirmModal.vue';
    import Task from '../../../models/task.js';
    import Create from './Create.vue';
    import Edit from './Edit.vue';
    import { useToast } from 'vue-toast-notification';

    export default {

        components: {Create, Edit, ConfirmModal},

        data() {
            return {
                create: false,
                edit: false,
                tasks: [],
                confirm: {body: '', active: false, id: null},
            }
        },

        methods: {
            setTask(taskData) {
                let task = new Task();
                task.setAttributesFromResponse(taskData);
                this.tasks.push(task);
            },

            async remove() {
                this.confirm.active = false;
                this.tasks.splice( this.tasks.map(function(e) { return e.id; }).indexOf(this.confirm.id), 1);
                await axios.delete('/tasks/' + this.confirm.id);
                useToast().success('Task verwijderd');
                this.confirm.id = null;
            },

            getTasks() {
                axios.get('tasks')
                .then(response => {
                    this.tasks = [];
                    for (let taskData of response.data.data) {
                        this.setTask(taskData);
                    }
                });
            }
        },

        created() {
            this.getTasks();


            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Taken'}
            ])
        }
    }
</script>
