<template>
    <div style="padding: 0 20px;">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Inkopen</h1>

                    <div class="horizontal-fab-button-holder">
                        <fab-button @click.native="create = true" icon="plus"></fab-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header-settings">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Van</span>
                    <DatePicker @input="getPurchases" v-model="purchaseFilters.from"></DatePicker>
                    <!-- <input type="date" @input="getPurchases" v-model="purchaseFilters.from" /> -->
                </div>
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Tot</span>
                    <DatePicker @input="getPurchases" v-model="purchaseFilters.till"></DatePicker>
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Locatie</span>
                    <MultiSelect
                        @change="getPurchases"
                        v-model="purchaseFilters.locations"
                        :options="locations.all().map(l => ({label: l.name, value: l.id}))"
                        placeholder="Maak een keuze"
                    ></MultiSelect>
                </div>
                <!-- <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Producten</span>
                    <MultiSelect
                        v-model="purchaseFilters.products"
                        :options="purchases.unique().all().map(p => ({label: p.name(), value: p.id}))"
                        placeholder="Maak een keuze"
                    ></MultiSelect>
                </div> -->
            </div>
        </div>

        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <div class="purchase-table-holder">
                        <table class="purchase-table">
                            <thead class="purchase-table-header">
                                <tr>
                                    <th>BOSSEN</th>
                                    <th v-for="header of headers" :colspan="header.contents.count()">
                                        {{ header.type.translate('name') }} {{ header.size.translate('name') }}
                                    </th>
                                    <th>TOTAAL</th>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <template v-for="header of headers">
                                        <th v-for="content of header.contents">{{ content.name }}</th>
                                    </template>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="purchases.count() > 0" >
                                <tr v-for="location of filteredLocations" :class="{deleted: location.deletedAt !== null}">
                                    <td><a
                                        class="purchase-location-link"
                                        @click="$router.push(`/purchase/${location.id}/${purchaseFilters.from}/${purchaseFilters.till}`)"
                                    >
                                        {{ location.name }}
                                    </a></td>
                                    <template v-for="header of headers">
                                        <td v-for="content of header.contents">
                                            {{ format(location.purchases.filter(p =>
                                                filterPurchaseByCcst(p, content, header)
                                            ).sum('amount'))  }}
                                        </td>
                                    </template>
                                    <td>{{ location.purchases.sum('amount') }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Totaal</td>
                                    <template v-for="header of headers">
                                        <td v-for="content of header.contents">
                                            {{ format(purchases.filter(p =>
                                                filterPurchaseByCcst(p, content, header)
                                            ).sum('amount')) }}
                                        </td>
                                    </template>
                                    <td>{{ purchases.sum('amount') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="locations.count() > 0">
            <create-product
                @submitted="submit"
                :date="today.format('YYYY-MM-DD')"
                :step="2"
                :locations="locations.all()"
                header="Nieuwe inkoop"
                @close="create = false"
                v-if="create"
            ></create-product>
        </div>
    </div>

</template>

<script setup>
    import moment from 'moment';
    import { ref, computed } from 'vue';
    import { useCcstStore } from './../../stores/ccst';
    import MultiSelect from '../../components/Form/MultiSelect.vue';
    import { collect } from "collect.js";
    import { useToast } from 'vue-toast-notification';
    import purchaseService from '../../services/http/purchase-service.js';

    const props = defineProps(['from', 'till']);
    const ccst = useCcstStore();
    const today = ref(moment());

    /*
    |--------------------------------------------------------------------------
    | Purchases
    |--------------------------------------------------------------------------
    */
    const format = function(number) {
        if(number === 0) {
            return '-';
        }

        return number;
    }

    const purchaseFilters = ref({
        from: (props.from ? moment(props.from) : moment()).startOf('day').format('YYYY-MM-DD'),
        till: (props.till ? moment(props.till) : moment()).endOf('day').format('YYYY-MM-DD'),
        locations: [],
        products: []
    })

    const getPurchases = function() {
        const filters = {
            0: 'withoutDefaultWith'
        };

        if (purchaseFilters.value.from) {
            filters['date_is_greater_or_equal_than'] = purchaseFilters.value.from;
        }

        if (purchaseFilters.value.till) {
            filters['date_is_lower_or_equal_than'] = purchaseFilters.value.till;
        }

        if (purchaseFilters.value.locations && purchaseFilters.value.locations.length > 0) {
            filters['location_id_is_in'] = purchaseFilters.value.locations;
        }



        purchaseService.get({filters: filters}).then(purchaseModels => {
            purchases.value = purchaseModels;
        });
    }

    getPurchases();

    const purchases = ref(collect());

    const filterPurchaseByCcst = function(purchase, content, header){
        return (
            purchase.contentId === content.id
            && purchase.sizeId === header.size.id
            && purchase.typeId === header.type.id
        )
    }

    const sortPurchases = function(a, b) {
        if (a.type.sequence === b.type.sequence) {
            return a.size.id > b.size.id ? -1 : 1;
        }

        return a.type.sequence > b.type.sequence ? 1 : -1;
    }

    /*
    |--------------------------------------------------------------------------
    | Create purchase
    |--------------------------------------------------------------------------
    */
    import CreateProduct from '../../components/create-product/create-product.vue';
    const create = ref(false);
    const submit = async function(form) {
        await purchaseService.create(form.data());
        getPurchases();
        useToast().success('Inkoop opgeslagen');
    }

    /*
    |--------------------------------------------------------------------------
    | Locations
    |--------------------------------------------------------------------------
    */
    import LocationService from './../../services/http/location-service';
    const locations = ref(collect());
    LocationService.get({filters: {0: 'with_trashed', default_is: 0}}).then(locationModels => {
        locations.value = locationModels;
    });
    const filteredLocations = computed(() => locations.value.filter(l =>
        purchases.value.contains('locationId', l.id)
    ).map(l => {
        l.purchases = purchases.value.where('locationId', l.id);
        return l;
    }));

    /*
    |--------------------------------------------------------------------------
    | Headers
    |--------------------------------------------------------------------------
    */
    const headers = computed(() =>
        purchases.value
        .unique(purchase => purchase.typeId + purchase.sizeId)
        .map(purchase => ({
            type: ccst.types.find(t => t.id === purchase.typeId),
            size: ccst.sizes.find(s => s.id === purchase.sizeId),
            contents: purchases.value.filter(p =>
                    p.typeId === purchase.typeId
                    && p.sizeId === purchase.sizeId
                )
                .unique('contentId').sortBy('contentId')
                .map(p => ccst.contents.find(c => c.id === p.contentId))
        })).sort(sortPurchases)
    );
</script>
