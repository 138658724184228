<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="tasks">
                    <div class="page-header">
                        <h1>Rassen</h1>
                        <fab-button @click.native="openCreateBreedModal" icon="plus"></fab-button>
                    </div>

                    <div class="widget-holder">
                        <table class="styled-table">
                            <tr class="styled-table-row" v-for="(breed, index) of breeds" :key="index">
                                <td>{{ breed.name }}</td>
                                <td class="text-left">{{ breed.type.translate('name') }}</td>
                                <td class="text-left">{{ breed.fractions.count() ? breed.fractions.implode('milimeters', ', ') : '-' }}</td>
                                <td class="styled-table-row-icons">
                                    <a @click="openEditBreedModal(breed)"><icon name="pencil" /></a>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>

        <modal v-if="createEditFractionsModal" @close="createEditFractionsModal = false" @save="submit">
            <template v-slot:header>{{ createEditFractionsModal.id ? 'Ras wijzigen' : 'Nieuw ras' }}</template>
            <div>
                <span class="styled-label">Ras naam</span>
                <input class="styled-input" type="text" name="name" v-model="createEditFractionsModal.name">
                <br/>
                <br/>
                <span class="styled-label">Type</span>
                <select class="styled-select" v-model="createEditFractionsModal.typeId">
                    <option v-for="typeModel of types" :key="typeModel.id" :value="typeModel.id">{{ typeModel.translate('name') }}</option>
                </select>
                <br/>
                <br/>
                <span class="styled-label">Fracties</span>
                <MultiSelect
                    v-model="createEditFractionsModal.fractionsIds"
                    :options="fractions.map(f => ({label: f.milimeters.toString(), value: f.id})).all()"

                />
                <br/>
                <br/>
            </div>
        </modal>
    </div>
</template>

<script>

    import breedService from '../../services/http/breed-service';
    import fractionService from '../../services/http/fraction-service';
    import typeService from '../../services/http/type-service';
    import { collect } from "collect.js";
    import MultiSelect from '../../components/Form/MultiSelect.vue';
    import { useToast } from 'vue-toast-notification';

    export default {
        components: { MultiSelect },

        data() {
            return {
                fractions: collect(),
                breeds: collect(),
                types: collect(),

                createEditFractionsModal: false

            }
        },

        methods: {

            openCreateBreedModal()
            {
                this.createEditFractionsModal = {
                    id: null,
                    fractionsIds: [],
                    type_id: null,
                    name: ''
                }
            },

            openEditBreedModal(breed) {
                this.createEditFractionsModal = {
                    id: breed.id,
                    typeId: breed.type.id,
                    fractionsIds: breed.fractions.pluck('id').all(),
                    name: breed.name
                }
            },

            getFractions() {
                fractionService.get().then(fractions => {
                    this.fractions = fractions;
                });
            },

            getBreeds() {
                breedService.get({include: ['fractions', 'type']}).then(breeds => {
                    this.breeds = breeds;
                });
            },

            getTypes() {
                typeService.get({filters: {0: 'hasOneChild'}}).then(types => {
                    this.types = types;
                });
            },

            submit() {
                if (this.createEditFractionsModal.id) {
                    breedService.edit(this.createEditFractionsModal.id, {
                        name: this.createEditFractionsModal.name,
                        fraction_ids: this.createEditFractionsModal.fractionsIds.filter(f => f !== null),
                        type_id: this.createEditFractionsModal.typeId
                    }, {include: ['fractions', 'type']}).then((breed) => {
                        this.breeds.splice(this.breeds.search(b => b.id === breed.id), 1, [breed]);
                        this.createEditFractionsModal = false;
                        useToast().success('Ras gewijzigd');
                        document.querySelector('body').classList = '';
                    });
                } else {
                    breedService.create({
                        name: this.createEditFractionsModal.name,
                        fraction_ids: this.createEditFractionsModal.fractionsIds,
                        type_id: this.createEditFractionsModal.typeId
                    }, {include: ['fractions', 'type']}).then((breed) => {
                        this.breeds.push(breed);
                        this.createEditFractionsModal = false;
                        useToast().success('Ras aangemaakt');
                        document.querySelector('body').classList = '';
                    });
                }
            }

        },

        created() {
            this.getFractions();
            this.getBreeds();
            this.getTypes();

            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Rassen'}
            ])
        }
    }
</script>
