<template>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Production control</h1>
                    <button @click="openCreateEditProductionControl">
                        <fab-button icon="plus"></fab-button>
                    </button>
                    <ProductionControlsExportExcel
                        :from="from"
                        :till="till"
                        :fabButton="true"
                        :productionControlIds="productionControls.pluck('id').all()"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="page-header-settings">
        <div class="row">
            <div class="col-md-4 col-lg-3 col-xl-3 datepicker-styling">
                <span class="styled-label">From</span>
                <DatePicker @input="getProductionControls()" v-model="from" />
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 datepicker-styling">
                <span class="styled-label">Till</span>
                <DatePicker @input="getProductionControls()" v-model="till" />
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 datepicker-styling">
                <span class="styled-label">Sowdate from</span>
                <DatePicker @input="getProductionControls()" v-model="sowingFrom" />
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 datepicker-styling">
                <span class="styled-label">Sowdate till</span>
                <DatePicker @input="getProductionControls()" v-model="sowingTill" />
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3">
                <span class="styled-label">Locations</span>
                <MultiSelect
                    v-model="filteredLocations"
                    :options="locations.all().map(l => ({label: l.name, value: l.id}))"
                    placeholder="Locations"
                    @change="getProductionControls()"
                ></MultiSelect>
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3">
                <span class="styled-label">Types</span>
                <MultiSelect
                    v-model="filteredTypes"
                    :options="types.all().map(l => ({label: l.name, value: l.id}))"
                    placeholder="Radish types"
                    @change="getProductionControls()"
                ></MultiSelect>
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3">
                <span class="styled-label">Breeds</span>
                <MultiSelect
                    v-model="filteredBreeds"
                    :options="
                        breeds.filter(
                            b => filteredTypes.length > 0 ? filteredTypes.includes(b.typeId) : true
                        ).all().map(l => ({label: l.name, value: l.id}))
                    "
                    placeholder="Breeds"
                    @change="getProductionControls()"
                ></MultiSelect>
            </div>
        </div>
    </div>

    <div class="production-control-table-holder">
        <div class="table-holder">
            <table class="styled-table production-control">
                <thead class="styled-table-header">
                    <tr>
                        <th>Control date</th>
                        <th>Amount</th>
                        <th>Sowing date</th>
                        <th>Location</th>
                        <th>Row nr.</th>
                        <th>Type</th>
                        <th>Breed</th>
                        <th>Sow density</th>
                        <th>Fraction</th>
                        <th>Lot nr.</th>
                        <th>Grow light</th>
                        <th>Growing days</th>
                        <th>Yield/m2</th>
                        <th>Yield/day</th>
                        <th>Tuber weight</th>
                        <th>Tuber diameter</th>
                        <th>Tuber height</th>
                        <th>Leafage weight</th>
                        <th>Leafage length</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="styled-table-body" v-if="productionControls.count() > 0">
                    <tr class="styled-table-row" v-for="productionControl of productionControls">
                        <td>
                            <router-link :to="`/production-control/${productionControl.id}`">
                                <a class="styled-link">{{ productionControl.at.format('DD-MM-YYYY') }}</a>
                            </router-link>
                        </td>
                        <td>{{ productionControl.productionControlMeasurementsCount }}</td>
                        <td>{{ productionControl.sowedAt.format('DD-MM-YYYY') }}</td>
                        <td>{{ productionControl.location.name }}</td>
                        <td>{{ productionControl.rowNumber }}</td>
                        <td>{{ productionControl.type.name }}</td>
                        <td>{{ productionControl.breed ? productionControl.breed.name : '-' }}</td>
                        <td>{{ productionControl.sowingDensity }}</td>
                        <td>{{ productionControl.fraction ? productionControl.fraction.milimeters : 'N/A' }}</td>
                        <td>{{ productionControl.lotNumber }}</td>
                        <td>{{ productionControl.growlight }}</td>
                        <td>{{ productionControl.at.diff(productionControl.sowedAt, 'day') }}</td>
                        <td>{{ Math.round(productionControl.yield()) }}</td>
                        <td>{{ Math.round(productionControl.yield() / productionControl.at.diff(productionControl.sowedAt, 'day')) }}</td>
                        <td>
                            {{ avg(productionControl, 'tuberWeight') }}
                            <span class="splitter">|</span>
                            {{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberWeight').all()) }}
                        </td>
                        <td>
                            {{ avg(productionControl, 'tuberDiameter') }}
                            <span class="splitter">|</span>
                            {{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberDiameter').all()) }}
                        </td>
                        <td>
                            {{ avg(productionControl, 'tuberHeight') }}
                            <span class="splitter">|</span>
                            {{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('tuberHeight').all()) }}
                        </td>
                        <td>
                            {{ avg(productionControl, 'leavesWeight') }}
                            <span class="splitter">|</span>
                            {{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('leavesWeight').all()) }}
                        </td>
                        <td>
                            {{ avg(productionControl, 'leavesLength') }}
                            <span class="splitter">|</span>
                            {{ getStandardDeviation(productionControl.productionControlMeasurements.pluck('leavesLength').all()) }}
                        </td>
                        <td class="icon-holder">
                            <a v-tooltip="'Image'" v-if="productionControl.fileModel" target="_blank" :href="productionControl.fileModel.publicUrl">
                                <Icon name="photo" />
                            </a>
                            <ProductionControlExportExcel v-if="productionControl.productionControlMeasurementsCount >= 1" :productionControl="productionControl" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="20">
                            <div class="no-data-placeholder" style="padding: 40px 0;">
                                Geen resultaat gevonden
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <CreateEditProductionControlModal
        :data="createEditProductionControl"
        @close="createEditProductionControl = null"
        :types="types" :locations="locations"
        @saved="createEditProductionControl = null; getProductionControls()"
    />

</template>

<script setup>
    import moment from 'moment';
    import { ref } from 'vue';
    import collect from 'collect.js';
    import LocationService from './../../services/http/location-service';
    import TypeService from './../../services/http/type-service';
    import MultiSelect from './../../components/Form/MultiSelect.vue';
    import ProductionControlService from './../../services/http/production-control-service';
    import ProductionControlExportExcel from './ProductionControlExportExcel.vue';
    import ProductionControlsExportExcel from './ProductionControlsExportExcel.vue';
    import CreateEditProductionControlModal from './CreateEditProductionControlModal.vue';
    import BreedService from './../../services/http/breed-service';

    const emit = defineEmits(['breadcrumbs'])

    const from = ref(moment().startOf('year').format('YYYY-MM-DD'));
    const till = ref(moment().format('YYYY-MM-DD'));

    const sowingFrom = ref(null);
    const sowingTill = ref(null);

    const locations = ref(collect());
    const filteredLocations = ref([]);

    const breeds = ref(collect());
    const filteredBreeds = ref([]);

    const types = ref(collect());
    const filteredTypes = ref([]);

    const productionControls = ref(collect());

    const filtersChanged = function() {
        getProductionControls();
    }

    const getStandardDeviation = function(array) {
        const length = array.length;
        if (length <= 0) return 0;
        const mean = array.reduce((a, b) => a + b) / length;
        return Math.round(Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / length) * 10) / 10
    }

    const avg = function(productionControl, attr) {
        if (productionControl.productionControlMeasurements.count() <= 0) {
            return 0;
        }

        return Math.round(productionControl.productionControlMeasurements.avg(attr)*10)/10;
    }

    /*
    |--------------------------------------------------------------------------
    | Create / Edit
    |--------------------------------------------------------------------------
    */
    const createEditProductionControl = ref(null);
    const openCreateEditProductionControl = function() {
        createEditProductionControl.value = {
            at: moment().format('YYYY-MM-DD'),
            sowed_at: null,
            location_id: null,
            row_number: null,
            type_id: null,
            breed_id: null,
            sowing_density: null,
            fraction_id: null,
            lot_number: null,
            growlight: null,
            tray_depth: 1,
            normal_sowing_depth: true,
            normal_substrate_strategy: true,
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */

    const getLocations = function() {
        LocationService.get().then(locationModels => {
            locations.value = locationModels;
        });
    }
    getLocations();

    const getBreeds = function() {
        BreedService.get().then(breedModels => {
            breeds.value = breedModels;
        });
    }
    getBreeds();

    const getTypes = function() {
        TypeService.get({
            filters: {'has_breeds': 1},
            include: ['breeds']
        }).then(typeModels => {
            types.value = typeModels;
        });
    }
    getTypes();

    const getProductionControls = function()
    {
        const filters = {
            'order_by_desc': 'at',
            ...getFilters()
        };

        ProductionControlService.get({
            include: [
                'fraction', 'type', 'location', 'countProductionControlMeasurements',
                'productionControlMeasurements', 'file', 'breed'
            ],
            filters
        }).then(productionControlModels => {
            productionControls.value = productionControlModels;
        });
    }

    const getFilters = function() {
        const filters = {};

        if (from.value) {
            filters['at_is_greater_or_equal_than'] = from.value;
        }

        if (till.value) {
            filters['at_is_lower_or_equal_than'] = till.value;
        }

        if (sowingFrom.value) {
            filters['sowed_at_is_greater_or_equal_than'] = sowingFrom.value;
        }

        if (sowingTill.value) {
            filters['sowed_at_is_lower_or_equal_than'] = sowingTill.value;
        }

        if (filteredLocations.value.length > 0) {
            filters['location_id_is_in'] = filteredLocations.value;
        }

        if (filteredTypes.value.length > 0) {
            filters['type_id_is_in'] = filteredTypes.value;
        }

        if (filteredBreeds.value.length > 0) {
            filters['breed_id_is_in'] = filteredBreeds.value;
        }

        return filters;
    }

    filtersChanged();

    emit('breadcrumbs', [
        {label: 'Production control'},
    ]);
</script>
