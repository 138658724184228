<template>
    <a @click="goToWorkLists()">
        <div class="users-list-user-holder">
            <div class="users-list-avatar">
                <img :src="user.avatar('small') ? user.avatar('small').publicUrl : '/img/profile-placeholder.jpg'"/>
            </div>
            <div class="users-list-name">{{ user.name() }}</div>
            <div class="users-list-name">{{ user.companyName }}</div>
            <div class="users-list-name">{{ user.userType.translate('name') }}</div>
            <div class="users-list-active">{{ user.deactivatedAt ? 'Inactief' : (user.absent ? 'Afwezig' : ' ') }}</div>
            <div class="users-list-name d-none d-md-inline-block text-center" v-tooltip="'Laatste werksessie'">{{ user.lastWorkSession ? user.lastWorkSession.toDate('startsAt').format('DD-MM-YYYY') : '-' }}</div>

            <div class="users-list-worklist-history">
                <div
                    v-tooltip="tooltip(subtractWeeks)"
                    class="history-week"
                    :class="{
                        'has-worked': hasWorked(subtractWeeks),
                        'is-payed': isPayed(subtractWeeks)
                    }"
                    v-for="subtractWeeks of [4,3,2,1]"
                >

                    <a @click.stop="goToWorkLists(today.clone().subtract(subtractWeeks, 'week'))"  v-if="activeYear === today.year() && user.userType.name === 'freelancer'">
                        W{{ today.clone().subtract(subtractWeeks, 'week').format('W') }}
                    </a>
                </div>
            </div>
        </div>
    </a>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import moment from 'moment'

    const props = defineProps(['activeYear', 'user', 'activeWeek'])
    const router = useRouter();
    const today = moment();

    const tooltip = function(subtractWeeks) {
        const worked = hasWorked(subtractWeeks);
        const payed = isPayed(subtractWeeks);

        if (worked && payed) {
            return 'Uitbetaald';
        } else if (worked) {
            return 'Gewerkt maar niet uitbetaald';
        } else {
            return 'Niet gewerkt';
        }
    }

    const hasWorked = function(substractWeeks) {
        const date = today.clone().subtract(substractWeeks, 'week')

        return (props.user.lastFourWeekWorkSessions.first(w => {
            return w.startsAt.isoWeek() === date.isoWeek()
        }) !== undefined)
    }

    const isPayed = function(substractWeeks) {
        const date = today.clone().subtract(substractWeeks, 'week')

        return (props.user.payOuts.first(p => {
            return date.isoWeek() === p.weeknumber
        }) !== undefined)
    }

    const goToWorkLists = function(date = null) {
        const activeYear = date ? date.isoWeekYear() : props.activeYear;
        const week = date ? '/' + date.isoWeek() : null;

        //console.log(date.format('d-m-Y'));
        if (props.user.userType.name === 'employee') {
            router.push('/users/' + props.user.id + '/permanent-work-hours/' + activeYear + (week ? week : ''));
            return;
        }

        router.push('/users/' + props.user.id + '/work-lists/' + activeYear + (week ? week : ''));
    };
</script>
