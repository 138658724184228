<template>
    <div class="container">

        <div class="errors" v-if="errors">
            <div class="error" v-for="error of errors">{{ error[0] }}</div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="page-header">
                    <h1>
                        Werklijst {{ user.name() }} - {{ activeYear }}
                    </h1>

                    <div class="save-and-cancel-buttons" v-if="authUser.can('work_lists')">
                        <fab-button :class="{danger: editting}" @click.native="toggleEdit" :icon="! editting ? 'pencil' : 'x-mark'"></fab-button>
                        <fab-button class="success" @click.native="save()" v-if="editting" icon="check"></fab-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="week-select">
                    <label>
                        <span class="styled-label">
                            Weeknummer
                        </span>
                        <select class="styled-select" @change="weekChanged()" v-model="activeWeek">
                            <option :value="week" v-for="(week, index) of weeks" :key="index">{{ week.weekNr }}</option>
                        </select>
                    </label>
                </div>
            </div>
        </div>

        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <div class="worklist-holder">
                        <table class="worklist-table worked-hours">
                            <thead class="worklist-table-header">
                                <tr>
                                    <th>Uren</th>
                                    <th>Ma<br/> {{ monday.clone().add(0, 'DAY').format('DD-MM') }}</th>
                                    <th>Di<br/> {{ monday.clone().add(1, 'DAY').format('DD-MM') }}</th>
                                    <th>Wo<br/> {{ monday.clone().add(2, 'DAY').format('DD-MM') }}</th>
                                    <th>Do<br/> {{ monday.clone().add(3, 'DAY').format('DD-MM') }}</th>
                                    <th>Vr<br/> {{ monday.clone().add(4, 'DAY').format('DD-MM') }}</th>
                                    <th>Za<br/> {{ monday.clone().add(5, 'DAY').format('DD-MM') }}</th>
                                    <th>Zo<br/> {{ monday.clone().add(6, 'DAY').format('DD-MM') }}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="attribute of attributes">
                                    <tr>
                                        <td>{{ attribute.label }}</td>
                                        <td v-for="(dayData, index) of weekData">
                                            <span v-if="! editting">{{ dayData[attribute.name] }}</span>
                                            <input type="text" v-else v-model="formData[index][attribute.name]" />
                                        </td>
                                        <td></td>
                                    </tr>
                                </template>
                                <tr>
                                    <td><b>Totaal</b></td>
                                    <td v-for="(dayData, index) of weekData">
                                        <span>{{ dayTotal(dayData) }}</span>
                                    </td>
                                    <td>{{ total() }}</td>
                                </tr>

                                <!--
                                <tr class="worklist-pause-row">
                                    <td>Pauze</td>
                                    <td v-for="(dayData, index) of (editting ? formData : weekData)">
                                        <span>{{
                                            calculatable(dayData.startsAt, dayData.endsAt)
                                            ? formatMinutes(getPauseMinutes(dayData.startsAt, dayData.endsAt))
                                            :  '-'
                                        }}</span>
                                    </td>
                                    <td></td>
                                </tr> -->
                            </tbody>
                            <!-- <tfoot class="worklist-table-total">
                                <tr>
                                    <td>Totaal</td>
                                    <td v-for="(dayData, index) of (editting ? formData : weekData)">
                                        <span>{{ formatMinutes(getMinutesWorked(dayData.startsAt, dayData.endsAt), false) }}</span>
                                    </td>
                                    <td>{{ formatMinutes(
                                        (editting ? formData : weekData).reduce((curAmount, d) =>
                                            curAmount + getMinutesWorked(d.startsAt, d.endsAt), 0
                                        ))
                                    }}</td>
                                </tr>
                            </tfoot> -->
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { useToast } from 'vue-toast-notification';
    import collect from 'collect.js';
    import PermanentWorkSessionService from '../../../services/http/permanent-work-session-service';
    import User from '../../../models/user.js';
    import { useAuthStore } from '../../../stores/auth';
    import moment from 'moment';
    import { computed, ref } from 'vue';

    const props = defineProps(['id', 'activeYear']);
    const emits = defineEmits(['breadcrumbs']);

    const attributes = [
        {name: 'startsAt', label: 'Starttijd'},
        {name: 'endsAt', label: 'Eindtijd'},
        {name: 'pause', label: 'Pauze'},
        {name: 'leave', label: 'Verlof'},
        {name: 'sickness', label: 'Ziekte'}
    ];

    /*
    |--------------------------------------------------------------------------
    | Authorized
    |--------------------------------------------------------------------------
    */
    const authUser = useAuthStore().user;
    import { useRouter } from 'vue-router';
    if (! authUser.can('work_lists') && authUser.id != props.id) {
        useRouter().push('/dashboard');
    }

    /*
    |--------------------------------------------------------------------------
    | Edit
    |--------------------------------------------------------------------------
    */

    const formData = ref([]);
    const editting = ref(false);
    const toggleEdit = function() {
        formData.value = [];
        editting.value = ! editting.value;
        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const permanentWorkSession = permanentWorkSessions.value.first(ws => ws.at.isoWeekday() === dayOfWeek);

            const formDataRow = {id: permanentWorkSession ? permanentWorkSession.id : null};
            for (const attribute of attributes) {
                formDataRow[attribute.name] = (permanentWorkSession && permanentWorkSession[attribute.name] ? permanentWorkSession[attribute.name] : null)

            }
            formData.value.push(formDataRow);
        }
    };

    /*
    |--------------------------------------------------------------------------
    | Work sessions
    |--------------------------------------------------------------------------
    */

    const user = ref(new User());

    const permanentWorkSessions = ref(collect());
    const getPermanentWorkSessions = function() {
        PermanentWorkSessionService.get({filters: {
            between: `${activeWeek.value.from.format('YYYY-MM-DD')},${activeWeek.value.till.format('YYYY-MM-DD')}`,
            user_id: props.id,
            order: 'starts_at,asc'
        }}).then(permanentWorkSessionModels => {
            permanentWorkSessions.value = permanentWorkSessionModels;
        });
    };

    const getUser = function() {
        return axios.get('users?id=' + props.id + '&include=avatars,documents,userType')
        .then(response => {
            user.value = new User();
            user.value.setAttributesFromResponse(response.data.data[0]);
        });
    };

    const errors = ref(null);
    const save = function() {

        const dayOfTheWeek = monday.value.clone();

        const deleteIds = [];
        const updateData = [];

        for (const dayData of formData.value) {
            if (dayData.startsAt || dayData.endsAt || dayData.pause || dayData.leave || dayData.sickness) {
                updateData.push({
                    id: (dayData.id ? dayData.id : null),
                    at: dayOfTheWeek.format('YYYY-MM-DD'),
                    starts_at: dayData.startsAt,
                    ends_at: dayData.endsAt,
                    pause: dayData.pause,
                    leave: dayData.leave,
                    sickness: dayData.sickness,
                    user_id: props.id
                });
            } else if (dayData.id) {
                deleteIds.push(dayData.id);
            }

            dayOfTheWeek.add(1, 'day');
        }

        errors.value = null;
        return PermanentWorkSessionService.updateMany({
            updateData,
            deleteData: deleteIds
        }).then(() => {
            editting.value = false;
            useToast().success('Werksessies opgeslagen')
            return getPermanentWorkSessions();
        }).catch(error => {
            if (error.response.status === 422) {
                errors.value = error.response.data.errors;
            }
        });
    };

    const weekChanged = function() {
        editting.value = false;
        getPermanentWorkSessions();
    }

    /*
    |--------------------------------------------------------------------------
    | View/Show
    |--------------------------------------------------------------------------
    */

    const dayTotal = function(data, returnMinutes = false) {
        const startsAtTime = data['startsAt'] === '-' || data['endsAt'] === '-' ? [0, 0] : data['startsAt'].split(':');
        const endsAtTime = data['startsAt'] === '-' || data['endsAt'] === '-' ? [0, 0] : data['endsAt'].split(':');

        const pauseTime = data['pause'] === '-' ? [0, 0] : data['pause'].split(':');
        const leaveTime = data['leave'] === '-' ? [0, 0] : data['leave'].split(':');
        const sicknessTime = data['sickness'] === '-' ? [0, 0] : data['sickness'].split(':');

        const minutes = ((parseInt(endsAtTime[0]) - parseInt(startsAtTime[0]) - parseInt(pauseTime[0]) + parseInt(leaveTime[0]) + parseInt(sicknessTime[0])) * 60)
            + (parseInt(endsAtTime[1]) - parseInt(startsAtTime[1]) - parseInt(pauseTime[1]) + parseInt(leaveTime[1]) + parseInt(sicknessTime[1]));

        if (returnMinutes) {
            return minutes;
        }

        return String(Math.floor(minutes / 60)).padStart(2, '0') + ':' + String(minutes % 60).padStart(2, '0');
    }

    const total = function() {
        let minutes = 0;
        for (const dayData of weekData.value) {
            minutes += dayTotal(dayData, true);
        }

        return String(Math.floor(minutes / 60)).padStart(2, '0') + ':' + String(minutes % 60).padStart(2, '0');
    }

    const getDateOfMonday = function(momentjs = false) {
        if(momentjs) {
            return activeWeek.value.from.clone();
        }

        return activeWeek.value.from.clone().toDate();
    };

    const monday = computed(() => {
        return getDateOfMonday(true);
    });

    const weekData = computed(() => {
        const weekData = [];

        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const permanentWorkSession = permanentWorkSessions.value.first(ws => ws.at.isoWeekday() === dayOfWeek);

            const dayData = {}
            for (const attribute of attributes) {
                dayData[attribute.name] = permanentWorkSession && permanentWorkSession[attribute.name] ? permanentWorkSession[attribute.name] : '-'

            }

            weekData.push(dayData)
        }

        return weekData;
    });



    // Set weeks
    const weeks = ref([]);
    const setWeeks = function () {

        const date = moment('01-01-' + props.activeYear, 'DD-MM-YYYY').startOf('isoWeek');
        while (date.year() == props.activeYear || date.clone().endOf('isoWeek').year() == props.activeYear) {

            weeks.value.push({
                weekNr: date.format('W'),
                from: date.clone(),
                till: date.clone().endOf('isoWeek')
            })
            date.add(1, 'week');
        }
    }

    setWeeks();
    const activeDate = (new Date()).getFullYear() != props.activeYear ? moment('01-01-' + props.activeYear, 'DD-MM-YYYY') : moment();
    weeks.value = weeks.value.reverse();
    const activeWeek = ref(weeks.value.find(wk => wk.from.isSame(activeDate, 'week')));
    weeks.value = weeks.value.filter((week) => week.till.diff(moment(), 'week') < 1);

    getPermanentWorkSessions();

    getUser().then(() => {
        emits('breadcrumbs', [
            {to: `/users/work-lists`, label: 'Werklijsten'},
            {label: user.value.name()}
        ]);
    });
</script>
