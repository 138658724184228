<template>
    <Teleport to="body">
        <div @click.self="close()" v-bind="$attrs" v-on:keyup.enter="$emit('save')" style="background: rgba(0, 0, 0, .4); display: block;" :class="{'no-fade': noFade}" class="modal show" tabindex="-1">
            <div class="modal-dialog" :class="{'modal-lg' : large === true, 'modal-fullscreen': extraLarge === true}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <slot name="header">Pop-up</slot>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <slot></slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="before-buttons"></slot>

                        <button class="modal-button" @click="close()" type="button">
                            <slot name="close">Sluiten</slot>
                        </button>

                        <button class="modal-button success" type="button" v-if="! excludeSaveButton" @click="$emit('save')">
                            <slot name="save">Opslaan</slot>
                        </button>
                        <slot name="buttons"></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
    export default {
        emits: ['close', 'save'],
        props: ['extraLarge', 'large', 'excludeSaveButton', 'noFade'],
        methods: {
            close() {
                this.$emit('close');
                document.querySelector('body').classList = '';
            }
        },
    }
</script>
