<template>
    <div class="container" v-if="show">

        <div data-scroll-bar-container class="hover-scroll-bar-container" id="top_scroll_bar">
            <div class="four hover-scroll-bar" data-speed="-12"></div>
            <div class="three hover-scroll-bar" data-speed="-6"></div>
            <div class="two hover-scroll-bar" data-speed="-3"></div>
            <div class="one hover-scroll-bar" data-speed="-1"></div>
        </div>

        <div data-scroll-bar-container class="hover-scroll-bar-container" id="bottom_scroll_bar">
            <div class="one hover-scroll-bar" data-speed="1"></div>
            <div class="two hover-scroll-bar" data-speed="3"></div>
            <div class="three hover-scroll-bar" data-speed="6"></div>
            <div class="four hover-scroll-bar" data-speed="12"></div>
        </div>

        <div id="harvest_assigments" data-harvest-assignments>

            <div class="container">

                <div class="row">
                    <div class="col">
                        <div class="page-header">
                            <h1>Bosopdrachten</h1>
                            <fab-button @click.native="openCreateModal(null, null);" icon="plus"></fab-button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="page-header-day-select">
                            <a :class="{'disabled': date.isSame(new Date(), 'day')}" @click="changeDay(-1)">
                                <Icon name="arrow-left" type="solid"/>
                            </a>

                            <span>{{ presentDate }}</span>

                            <a @click="changeDay(1)">
                                <Icon name="arrow-right" type="solid"/>
                            </a>
                            <span class="future-assignments">{{ allHarvestAssignments.filter((ha) => ha.plannedAt.isAfter(date)).count() }}</span>
                        </div>
                    </div>
                </div>

                <!-- NOT ASSIGNED HARVEST ASSIGNMENTS ALERT -->
                <div v-if="unassignedHarvestAssignments.count() > 0" class="alert-holder">
                    <div v-if="unassignedHarvestAssignments.count() === 1" class="alert alert-danger">
                        <Icon name="bell-alert" type="solid" /> Er is <b>{{ unassignedHarvestAssignments.count() }} bosopdracht</b> zonder gekoppelde medewerkers.
                    </div>
                    <div v-else class="alert alert-danger">
                        <Icon name="bell-alert" type="solid" /> Er zijn <b>{{ unassignedHarvestAssignments.count() }} bosopdrachten</b> zonder gekoppelde medewerkers.
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="rush-container">
                            <div data-dropzone-container="rush">
                                <HarvestAssignment
                                    @editHarvestedOfUser="openEditHarvestedOfUserModal"
                                    @forceHarvestAssignment="openForceHarvestAssignmentModal"
                                    @edit="editHarvestAssignment(harvestAssignment)"
                                    :class="{'editing': edit.id === harvestAssignment.id}"
                                    v-for="(harvestAssignment, index) of rushHarvestAssignments"
                                    :key="index"
                                    time="-"
                                    :harvestAssignment="harvestAssignment"
                                    :users="users"
                                    @createOnPlace="openCreateModal"
                                    :last="index === rushHarvestAssignments.count()-1"
                                    :authUser="user"
                                />
                                <div class="create-harvest-between new-harvest-assignment"  @click="openCreateModal(0, 1)"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col" data-dropzone-container="normal">
                        <HarvestAssignment
                            @editHarvestedOfUser="openEditHarvestedOfUserModal"
                            @forceHarvestAssignment="openForceHarvestAssignmentModal"
                            @edit="editHarvestAssignment(harvestAssignment)"
                            :class="{'editing': edit.id === harvestAssignment.id}"
                            v-for="(harvestAssignment, index) of assignedHarvestAssignments"
                            :key="index"
                            time="-"
                            :harvestAssignment="assignedHarvestAssignments.first(ha => ha.id === harvestAssignment.id)"
                            :authUser="user"
                            :users="users"
                            @createOnPlace="openCreateModal"
                            :last="index === assignedHarvestAssignments.count()-1"
                        />
                        <div class="create-harvest-between new-harvest-assignment" @click="openCreateModal(0, 0)"></div>
                    </div>
                </div>

                <div class="new-harvest-assignments" data-new-assignments>
                    <div v-if="newHarvestAssignments.count() <= 0">
                        <span class="no-data-placeholder">
                            Er zijn geen nieuwe bosopdrachten
                        </span>
                    </div>
                    <HarvestAssignment
                        @forceHarvestAssignment="openForceHarvestAssignmentModal"
                        v-for="(harvestAssignment, index) of newHarvestAssignments"
                        :key="index"
                        time="-"
                        :harvestAssignment="harvestAssignment"
                        :users="users"
                        :authUser="user"
                        @click.native="confirm = harvestAssignment.id"
                        @createOnPlace="openCreateModal"
                        :class="{'editing': edit.id === harvestAssignment.id}"
                    />
                </div>

                <CreateProduct
                    :side-modal="true"
                    :noFade="true"
                    @submitted="saveHarvestAssignment"
                    :date="{date: date, disabledDates: {to: today.clone().subtract(1, 'day').toDate()}}"
                    header="Nieuwe bosopdracht"
                    @close="closeModal()"
                    v-if="create"
                />

                <CreateHarvestRegistration
                    v-if="editHarvestedOfUserModal"
                    :data="editHarvestedOfUserModal"
                    @close="closeModal()"
                />

                <EditHarvestAssignment
                    :today="date.isSame(new Date(), 'day')"
                    :firstLocationsOfDay="firstLocationsOfDay"
                    @remove="confirm = $event; edit = false"
                    v-if="edit"
                    :users="users"
                    :harvestAssignment="edit"
                    :locations="locations"
                    @close="closeModal()"
                    :locationPerUser="locationsPerUserPerHarvestAssignment[edit.id]"
                />

                <ConfirmModal
                    v-if="forceHarvestAssignmentModal"
                    :body="`Weet je zeker dat je deze bosopdracht wilt forceren naar ${forceHarvestAssignmentModal.user.name()}?`"
                    title="Forceren bevestigen"
                    @yes="forceHarvestAssignment(forceHarvestAssignmentModal.user.id, forceHarvestAssignmentModal.harvestAssignment.id)"
                    @no="forceHarvestAssignmentModal = null"
                />

                <ConfirmModal
                    v-if="confirm !== false"
                    :body="(
                            openHarvestAssignments.first(ha => ha.id === confirm) === undefined
                            || openHarvestAssignments.first(ha => ha.id === confirm).harvested == 0
                        ) ? 'Weet je zeker dat je deze bosopdracht wilt verwijderen?'
                        : 'Weet je zeker dat je deze bosopdracht wilt afronden?'
                    "
                    @yes="openHarvestAssignments.first(ha => ha.id === confirm).harvested == 0 ? remove() : complete()"
                    @no="confirm = false"
                />
            </div>
        </div>

    </div>
</template>

<script setup>
    import FormulaFactor from '../../models/formula-factor.js';
    import WorkSession from '../../models/work-session.js';
    import Location from '../../models/location.js';
    import User from '../../models/user.js';
    import Size from '../../models/size.js';
    import Content from '../../models/content.js';
    import Chest from '../../models/chest.js';
    import Type from '../../models/type.js';
    import EditHarvestAssignment from './EditHarvestAssignment.vue';
    import CreateHarvestRegistration from './CreateHarvestRegistration.vue';
    import CreateProduct from './../../components/create-product/create-product.vue';
    import ConfirmModal from '../../components/ConfirmModal.vue';
    import HarvestAssignment from './HarvestAssignment.vue';
    import calculateHarvestAssignmentTimes from './../../helpers/calculate-harvest-assignment-times';
    import moment from 'moment';
    import { useHarvestAssignmentStore } from '../../stores/harvestAssignments';
    import { useAuthStore } from '../../stores/auth';
    import { useToast } from 'vue-toast-notification';
    import collect from 'collect.js';
    import { ref, computed } from 'vue';
    import harvestAssignmentService from '@/services/http/harvest-assignment-service';

    const locations = ref([]);
    const users = ref([]);
    const edit = ref(false);
    const create = ref(false);
    const to_id = ref(null);
    const to_rush = ref(null);
    const contents = ref([]);
    const sizes = ref([]);
    const types = ref([]);
    const chests = ref([]);
    const confirm = ref(false);
    const workSessionsTimer = ref(null);
    const formulaFactors = ref([]);
    const editHarvestedOfUserModal = ref(false);
    const forceHarvestAssignmentModal = ref(null);
    const today = ref(moment());
    const date = ref(moment());
    const presentDate = ref(moment().format('DD-MM-YYYY'));
    const show = ref(true);

    const user = useAuthStore().user;

    /*
    |--------------------------------------------------------------------------
    | Harvest assignments, states and getters
    |--------------------------------------------------------------------------
    */
    const allHarvestAssignments = useHarvestAssignmentStore().allHarvestAssignments;

    // Harvest assignment default sorting
    const defaultSort = (a, b) => {
        if (a.rush > b.rush) {
            return -1;
        }

        if (b.rush > a.rush) {
            return 1;
        }

        return ((a.sequence < b.sequence) ? -1 : 1)
    };

    // Rush, Assigned and New harvest assignments
    const filter = (harvestAssignments, rush, isNew) => harvestAssignments.filter(ha => (
        ha.rush == rush && ha.new == isNew && (! ha.done() || ! ha.locationId)
    )).sort(defaultSort);
    const rushHarvestAssignments = computed(() => filter(openHarvestAssignments.value, 1, 0));
    const assignedHarvestAssignments = computed(() => filter(openHarvestAssignments.value, 0, 0));
    const newHarvestAssignments = computed(() => filter(openHarvestAssignments.value, 0, 1));

    /// Open harvest assignments
    const openHarvestAssignments = computed(() =>
    {
        const users = [];

        const harvestAssignments = _.cloneDeep(useHarvestAssignmentStore().allHarvestAssignments);
        const alteredHarvestAssignment = _.cloneDeep(useHarvestAssignmentStore().alteredHarvestAssignment);

        if (edit.value && alteredHarvestAssignment) {
            harvestAssignments.splice(
                harvestAssignments.search((ha) => alteredHarvestAssignment.id === ha.id),
                1,
                [alteredHarvestAssignment]
            );
        }

        const filteredHarvestAssignments = harvestAssignments.filter((ha) => (
            ! ha.done()
            && (
                date.value.isSameOrBefore(new Date(), 'day') && ha.plannedAt.isSameOrBefore(date.value, 'day')
                || date.value.isAfter(new Date(), 'day') && ha.plannedAt.isSame(date.value, 'day')

            )
            || ! ha.locationId && ha.plannedAt.isBefore(new Date(), 'day')
        )).sort(defaultSort);

        for (let harvestAssignment of filteredHarvestAssignments) {
            for (let user of harvestAssignment.users) {
                if (! users.map(u => u.id).includes(user.id)) {
                    users.push(user);
                }
            }
        }

        calculateHarvestAssignmentTimes(filteredHarvestAssignments, users, formulaFactors.value, date.value);

        return filteredHarvestAssignments;
    });

    // Unassignment harvest assignments (no users attached)
    const unassignedHarvestAssignments = computed(() => {
        return openHarvestAssignments.value.filter(
            ha => ha.users.count() === 0 && (! ha.done())
        ).sort(defaultSort);
    });


    /*
    |--------------------------------------------------------------------------
    | Harvest assignment actions, edit/delete/store/complete/force
    |--------------------------------------------------------------------------
    */
    // Open create/edit
    const openModal = function(type, object = true) {
        edit.value = false;
        create.value = false;
        editHarvestedOfUserModal.value = false;

        setTimeout(() => {
            type.value = object;
        }, 1)
    };

    const openEditHarvestedOfUserModal = function(user, harvestAssignment) {
        openModal(editHarvestedOfUserModal, {user, harvestAssignment});
    }

    // Close create/edit
    const closeModal = function() {
        edit.value = false;
        create.value = false;
        editHarvestedOfUserModal.value = false;
    };

    // Create
    const openCreateModal = function(id, rush) {
        to_id.value = id;
        to_rush.value = rush;
        openModal(create);
    };

    // Store
    const {storeHarvestAssignment} = useHarvestAssignmentStore();
    const saveHarvestAssignment = function(form) {
        if (! form.date || ! form.content_id || ! form.size_id || ! form.chest_id || ! form.type_id) {
            useToast().alert('Niet alle benodigde gegevens zijn ingevuld');
            return;
        }

        if (to_id.value !== null) {
            storeHarvestAssignment(form.data({planned_at: form.date, to_id: to_id.value, to_rush: to_rush.value}));
        } else {
            storeHarvestAssignment(form.data({planned_at: form.date}));
        }

        to_id.value = null;
        to_rush.value = null;

        document.querySelector('body').classList = '';
        useToast().success('Bosopdracht opgeslagen');
    };

    // Edit
    const editHarvestAssignment = function(harvestAssignment) {
        openModal(edit, harvestAssignment);
    };

    // Delete
    const remove = function() {
        useHarvestAssignmentStore().deleteHarvestAssignment(confirm.value).then(ha => {
            useToast().success('Bosopdracht verwijderd');
            confirm.value = false;
        });
    };

    // Complete
    const complete = function() {
        useHarvestAssignmentStore().completeHarvestAssignment(confirm.value).then(() => {
            useToast().success('Bosopdracht afgerond');
            confirm.value = false;
        });
    };

    const openForceHarvestAssignmentModal = function(userToForce, ha) {
        if (user.can('harvest_assignments_planning')) {
            forceHarvestAssignmentModal.value = {user: userToForce, harvestAssignment: ha}
        }
    }

    const forceHarvestAssignment = function(userId, harvestAssignmentId) {
        harvestAssignmentService.force(harvestAssignmentId, userId).then(response => {
            forceHarvestAssignmentModal.value = null;
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Navigation
    |--------------------------------------------------------------------------
    */
    const changeDay = function(value) {
        if (date.value.isSame(new Date(), 'day') && value === -1) {
            return;
        }

        date.value.add(value, 'day');
        presentDate.value = date.value.format('DD-MM-YYYY');
        date.value = date.value.clone();
    };


    /*
    |--------------------------------------------------------------------------
    | Work sessions
    |--------------------------------------------------------------------------
    */
    const getWorkSessions = function() {
        if (! users.value.length < 0) {
            return false;
        }

        axios.get('/work-sessions?user_ids=' + users.value.map(user => user.id).join(',') + '&active=1')
        .then(response => {
            for (const user of users.value) {
                user.workSessions = collect();
            }

            for (let workSessionData of response.data.data) {
                let workSession = new WorkSession();
                workSession.setAttributesFromResponse(workSessionData);

                let index = users.value.findIndex(user => user.id == workSession.user_id);
                if (index >= 0) {
                    users.value[index].workSessions.push(workSession);
                }
            }
        });
    }
    workSessionsTimer.value = setInterval(() => {getWorkSessions()}, 60000);

    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */

    // Current locations per user per harvest assignment
    const locationsPerUserPerHarvestAssignment = computed(() =>
    {
        const harvestAssignments = {};
        let currentLocationsPerUserPerHarvestAssignment = {};

        for (const harvestAssignment of openHarvestAssignments.value) {
            harvestAssignments[harvestAssignment.id] = Object.assign({}, currentLocationsPerUserPerHarvestAssignment);

            for (const user of harvestAssignment.users) {
                currentLocationsPerUserPerHarvestAssignment[user.id] = harvestAssignment.locationId;
            }
        }

        return harvestAssignments;
    })

    // First locations of day
    const firstLocationsOfDay = computed(() =>
    {
        const locationsPerUser = {};

        for (const ha of openHarvestAssignments.value) {
            for (const user of ha.users) {
                if (ha.locationId && ! (user.id in locationsPerUser)) {
                    locationsPerUser[user.id] = ha.locationId;
                }
            }
        }

        return locationsPerUser;
    });


    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */
    axios.get('/locations').then(response => {
        for (let locationData of response.data.data) {
            let location = new Location();
            location.setAttributesFromResponse(locationData);
            locations.value.push(location);
            locations.value.sort((a,b) => a.name > b.name ? 1 : -1);
        }
    });

    axios.get('/contents?with-count=latestHarvestAssignments').then(response => {
        for (let contentData of response.data.data) {
            let content = new Content();
            content.setAttributesFromResponse(contentData);
            contents.value.push(content);
        }
    });

    axios.get('/chests?with-count=latestHarvestAssignments')
    .then(response => {
        for (let chestData of response.data.data) {
            let chest = new Chest();
            chest.setAttributesFromResponse(chestData);
            chests.value.push(chest);
        }
    });

    axios.get('/sizes?with-count=latestHarvestAssignments')
    .then(response => {
        for (let sizeData of response.data.data) {
            let size = new Size();
            size.setAttributesFromResponse(sizeData);
            sizes.value.push(size);
        }
    });

    axios.get('/types?with-count=latestHarvestAssignments')
    .then(response => {
        for (let typeData of response.data.data) {
            let type = new Type();
            type.setAttributesFromResponse(typeData);
            types.value.push(type);
        }
    });

    axios.get('/users?absent_is=0&has-role=harvest-worker,minis-harvest-worker&include=harvestRegistrations.first=1,harvestRegistrations.harvestAssignment,roles,avatars,workSessions.active=1')
    .then(response => {
        for (let userData of response.data.data) {
            let user = new User();
            user.setAttributesFromResponse(userData);
            users.value.push(user);
        }
    });

    axios.get('/formula-factors')
    .then(response => {
        for (let formulaFactorData of response.data.data) {
            let formulaFactor = new FormulaFactor();
            formulaFactor.setAttributesFromResponse(formulaFactorData);
            formulaFactors.value.push(formulaFactor);
        }
    });

</script>
