import {AbstractModel} from './abstract-model.js'

export default class Location extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            default: null,
            latest_harvest_assignments_count: null
        }
    }

}