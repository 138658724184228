<template>
    <a @click="goToProfile()">
        <div class="users-list-user-holder">
            <div class="users-list-avatar">
                <img :src="user.avatar('small') ? user.avatar('small').public_url : '/img/profile-placeholder.jpg'"/>
            </div>

            <div class="users-list-name">{{ user.name() }}</div>
            <div class="users-list-type">{{ user.userType.translate('name') }}</div>
            <div class="users-list-active">{{ user.deactivated_at ? 'Inactief' : (user.absent ? 'Afwezig' : ' ') }}</div>

            <div class="users-list-document" v-tooltip="'Identiteitsbewijs'" :class="user.document('id') ? user.document('id').status() : 'empty'">
                <Icon :name="(user.document('id') ? user.document('id').statusIcon() : 'x-mark')" />
            </div>
            <div class="users-list-document" v-tooltip="'KvK'" :class="user.document('kvk') ? user.document('kvk').status() : 'empty'">
                <Icon :name="(user.document('kvk') ? user.document('kvk').statusIcon() : 'x-mark')" />
            </div>
            <div class="users-list-document" v-tooltip="'Contract'" :class="user.document('contract') ? user.document('contract').status() : 'empty'">
                <Icon :name="(user.document('contract') ? user.document('contract').statusIcon() : 'x-mark')" />
            </div>

            <!-- OVEREENKOMST -->
            <template v-if="user.userType.name === 'freelancer' || user.userType.name === 'temporary_worker'">
                <div class="users-list-document empty" v-if="agreement === null" v-tooltip="'Geen overeenkomst'">
                    <Icon name="x-mark" />
                </div>
                <div class="users-list-document danger" v-else-if="agreement.starts_at.format('YYYY-MM-DD') > today.format('YYYY-MM-DD')" v-tooltip="'Overeenkomst start vanaf ' + agreement.starts_at.format('DD-MM-YYYY')">
                    <Icon :name="(agreement.accepted_at !== null ? 'check' : 'x')" />
                </div>
                <div class="users-list-document good" v-else-if="agreement.starts_at.format('YYYY-MM-DD') <= today.format('YYYY-MM-DD')" v-tooltip="'Overeenkomst actief t/m ' + agreement.ends_at.format('DD-MM-YYYY')">
                    <Icon :name="(agreement.accepted_at !== null ? 'check' : 'x')" />
                </div>
            </template>
            <div class="users-list-document good" v-else>
                &nbsp;
            </div>

        </div>
    </a>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['user'],

        data() {
            return {
                agreement: null,
                today: moment()
            }
        },

        methods: {
            goToProfile() {
                this.$router.push('/users/' + this.user.id);
            },


        },

        created()
        {
            let agreements = _.orderBy(this.user.agreements.all(), 'starts_at');

            for (let agreement of agreements) {
                if (
                    agreement.ends_at.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
                ) {
                    this.agreement = agreement;
                    break;
                }
            }
        }
    };
</script>
