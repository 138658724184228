<template>
    <Modal v-if="data" @save="store">

        <template v-slot:header>New production control</template>

        <div id="modal-errors" class="alert-holder" v-if="Object.values(errors).length > 0">
            <div class="alert alert-danger">
                Production control could not be saved. Please check all the fields below.
            </div>
        </div>

        <div class="datepicker-styling">
            <label class="styled-label">
                <span>Control date</span>
                <DatePicker v-model="data.at" :min="today.format('YYYY-MM-DD')" />
                <div class="invalid-feedback" style="display: block;" v-if="'at' in errors" v-text="errors['at'][0]"></div>
            </label>
        </div>

        <div class="datepicker-styling">
            <label class="styled-label">
                <span>Sow date</span>
                <DatePicker v-model="data.sowed_at" placeholder="Choose an sowing date" />
                <div class="invalid-feedback" style="display: block;" v-if="'sowed_at' in errors" v-text="errors['sowed_at'][0]"></div>
            </label>
        </div>

        <label class="styled-label">
            <span>Location</span>
            <select class="styled-input" v-model="data.location_id">
                <option :value="null">Choose a location</option>
                <option v-for="location of locations" :value="location.id">{{ location.name }}</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'location_id' in errors" v-text="errors['location_id'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Row number</span>
            <input class="styled-input" type="text" v-model="data.row_number">
            <div class="invalid-feedback" style="display: block;" v-if="'row_number' in errors" v-text="errors['row_number'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Type</span>
            <select class="styled-input" v-model="data.type_id">
                <option :value="null">Choose a type</option>
                <option v-for="(type, index) of types" :value="type.id">{{ type.name }}</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'type_id' in errors" v-text="errors['type_id'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Breed</span>
            <select class="styled-input" v-model="data.breed_id">
                <option :value="null">Choose a {{ data.type_id ? 'breed' : 'type first' }}</option>
                <option v-if="data.type_id" v-for="breed of types.firstWhere('id', data.type_id).breeds" :value="breed.id">{{ breed.name }}</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'breed_id' in errors" v-text="errors['breed_id'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Fraction</span>
            <select class="styled-input" v-model="data.fraction_id">
                <option :value="null">Choose a fraction</option>
                <option v-for="(fraction, index) of fractions" :value="fraction.id">{{ fraction.milimeters }}</option>
                <option :value="null">Not applicable</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'fraction_id' in errors" v-text="errors['fraction_id'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Lot number</span>
            <input class="styled-input" type="text" v-model="data.lot_number">
            <div class="invalid-feedback" style="display: block;" v-if="'lot_number' in errors" v-text="errors['lot_number'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Grow light</span>
            <input class="styled-input" type="number" v-model="data.growlight">
            <div class="invalid-feedback" style="display: block;" v-if="'growlight' in errors" v-text="errors['growlight'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Tray depth</span>
            <select class="styled-input" v-model="data.tray_depth">
                <option :value="1">Normal</option>
                <option :value="2">Low</option>
                <option :value="3">Mid</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'tray_depth' in errors" v-text="errors['tray_depth'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Sowing density</span>
            <input class="styled-input" type="number" v-model="data.sowing_density">
            <div class="invalid-feedback" style="display: block;" v-if="'sowing_density' in errors" v-text="errors['sowing_density'][0]"></div>
        </label>

        <label class="styled-label">
            <span>Sowing depth</span>
            <select class="styled-input" v-model="data.normal_sowing_depth">
                <option :value="true">Normal</option>
                <option :value="false">Deep</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'normal_sowing_depth' in errors" v-text="errors['normal_sowing_depth'][0]"></div>
        </label>

        <label class="styled-label">
            <span>substrate strategy</span>
            <select class="styled-input" v-model="data.normal_substrate_strategy">
                <option :value="true">Normal</option>
                <option :value="false">Cellulose</option>
            </select>
            <div class="invalid-feedback" style="display: block;" v-if="'normal_substrate_strategy' in errors" v-text="errors['normal_substrate_strategy'][0]"></div>
        </label>

        <template v-slot:close>Close</template>
        <template v-slot:save>Save</template>
    </Modal>

</template>

<script setup>
    import fractionService from '@/services/http/fraction-service';
    import productionControlService from '@/services/http/production-control-service';
    import collect from 'collect.js';
    import { ref, watch } from 'vue';
    import { useToast } from 'vue-toast-notification';
    import moment from 'moment'

    const props = defineProps(['data', 'types', 'fractions', 'locations']);
    const emit = defineEmits(['saved']);

    watch(() => props.data, (newData) =>{
        if (newData === null) {
            errors.value = [];
        }
    });

    const fractions = ref(collect());
    const errors = ref([]);

    const today = ref(moment());

    const store = function() {
        productionControlService.create(props.data).then((productionControl) => {
            useToast().success('Saved');
            emit('saved');
        }).catch(error => {
            if (
                'response' in error
                && 'data' in error.response
                && 'errors' in error.response.data
            ) {
                errors.value = error.response.data.errors;
                document.querySelector('.modal').scrollTop = 0
            } else {
                useToast().error('Unknown error');
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */

    const getFractions = function() {
        fractionService.get().then(fractionModels => {
            fractions.value = fractionModels;
        });
    }
    getFractions();

</script>
