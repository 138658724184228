import {AbstractModel} from './abstract-model';
import User from './user.js';
import FileModel from './file-model.js';

export default class ProductionControlMeasurement extends AbstractModel {

    getMutators() {
        return {
            'createdAt': 'datetime',
            'tuberWeight': 'float',
            'tuberDiameter': 'float',
            'tuberHeight': 'float',
            'leavesWeight': 'float',
            'leavesLength': 'float'
        };
    }

    relations()
    {
        return {
            user: User
        }
    }

    relationPlural()
    {
        return {
            user: false
        }
    }

    presentYesNo(attribute)
    {
        return this[attribute] ? 'Yes' : 'No';
    }

}
