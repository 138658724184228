<template>
    <div style="padding: 0 20px;">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>{{ location ? location.name : 'Inkopen' }}</h1>

                    <div class="horizontal-fab-button-holder">
                        <fab-button @click.native="create = true" icon="plus"></fab-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header-settings">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Van</span>
                    <DatePicker @input="getPurchases" v-model="purchaseFilters.from" />
                </div>
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Tot</span>
                    <DatePicker @input="getPurchases" v-model="purchaseFilters.till" />
                </div>
                <!-- <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Producten</span>
                    <MultiSelect
                        v-model="purchaseFilters.products"
                        :options="purchases.unique().all().map(p => ({label: p.name(), value: p.id}))"
                        placeholder="Maak een keuze"
                    ></MultiSelect>
                </div> -->
            </div>
        </div>

        <div class="widget-holder">
            <div class="row">
                <div class="col-12">
                    <div class="purchase-table-holder">
                        <table class="purchase-table">
                            <thead class="purchase-table-header">
                                <tr>
                                    <th>BOSSEN</th>
                                    <th v-for="header of headers" :colspan="header.contents.count()">
                                        {{ header.type.translate('name') }} {{ header.size.translate('name') }}
                                    </th>
                                    <th>TOTAAL</th>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <template v-for="header of headers">
                                        <th v-for="content of header.contents">{{ content.name }}</th>
                                    </template>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody v-if="purchases.count() > 0">
                                <tr v-for="date of purchases.pluck('date').unique(d => d.format('DD-MM-YYYY'))">
                                    <td><a class="purchase-location-link" @click="openOverview(date)">
                                        {{ date.format('DD-MM-YYYY') }}
                                    </a></td>
                                    <template v-for="header of headers">
                                        <td v-for="content of header.contents">
                                            {{ format(purchases.filter(p =>
                                                filterPurchaseByCcst(p, content, header)
                                                && p.date.isSame(date, 'day')
                                            ).sum('amount')) }}
                                        </td>
                                    </template>
                                    <td>{{ format(purchases.filter(p => p.date.isSame(date, 'day')).sum('amount')) }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Totaal</td>
                                    <template v-for="header of headers">
                                        <td v-for="content of header.contents">
                                            {{ format(purchases.filter(p =>
                                                filterPurchaseByCcst(p, content, header)
                                            ).sum('amount')) }}
                                        </td>
                                    </template>
                                    <td>{{ format(purchases.sum('amount')) }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <OverviewModal
            v-if="overviewModal !== false"
            @edit="openEditModal"
            @remove="openRemoveModal"
            :purchases="overviewModal.purchases"
            @close="overviewModal = false"
            :date="overviewModal.date"
        />

        <CreateProduct
            :location-id="locationId"
            @submitted="submit"
            :date="moment().format('YYYY-MM-DD')"
            header="Nieuwe inkoop"
            @close="create = false"
            v-if="create" />

        <CreateProduct
            @submitted="update"
            v-if="editModal"
            :product="editModal"
            :date="{
                date: editModal.date.clone().add(2, 'hours')
            }"
            :amount="editModal.amount"
            start="amount"
            header="Inkoop wijzigen"
            @close="editModal = false"
        />

        <DeletePurchase @delete="deletePurchase" @close="removeModal = false" v-if="removeModal" />

    </div>

</template>

<script setup>
    import moment from 'moment';
    import { ref, computed } from 'vue';
    import { useCcstStore } from './../../stores/ccst';
    import { collect } from "collect.js";
    import { useToast } from 'vue-toast-notification';
    import purchaseService from '../../services/http/purchase-service.js';
    import OverviewModal from './_OverviewModal.vue';
    import DeletePurchase from './_DeletePurchase.vue';

    const props = defineProps(['from', 'till', 'locationId']);
    const emits = defineEmits(['breadcrumbs']);

    /*
    |--------------------------------------------------------------------------
    | Purchases
    |--------------------------------------------------------------------------
    */
    const format = function(number) {
        if(number === 0) {
            return '-';
        }

        return number;
    }

    const purchaseFilters = ref({
        from: (props.from ? moment(props.from) : moment()).startOf('day').format('YYYY-MM-DD'),
        till: (props.till ? moment(props.till) : moment()).endOf('day').format('YYYY-MM-DD'),
        products: []
    });

    const getPurchases = function() {
        purchaseService.get({filters: {
            date_is_greater_or_equal_than: purchaseFilters.value.from,
            date_is_lower_or_equal_than: purchaseFilters.value.till,
            location_id_is: props.locationId
        }}).then(purchaseModels => {
            purchases.value = purchaseModels;
        });
    }
    getPurchases();

    const purchases = ref(collect());

    const filterPurchaseByCcst = function(purchase, content, header) {
        return (
            purchase.contentId === content.id
            && purchase.sizeId === header.size.id
            && purchase.typeId === header.type.id
        )
    }

    const sortPurchases = function(a, b) {
        if (a.type.sequence === b.type.sequence) {
            return a.size.id > b.size.id ? -1 : 1;
        }

        return a.type.sequence > b.type.sequence ? 1 : -1;
    }

    /*
    |--------------------------------------------------------------------------
    | Overview modal
    |--------------------------------------------------------------------------
    */
    const overviewModal = ref(false);
    const openOverview = function(date) {
        overviewModal.value = {
            date: date,
            purchases: purchases.value.filter(p => (p.date.isSame(date, 'day') && p.locationId == props.locationId))
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Location
    |--------------------------------------------------------------------------
    */
    import locationService from './../../services/http/location-service';
    const location = ref(null);
    locationService.getById(props.locationId).then(locationModel => {
        location.value = locationModel;
        emits('breadcrumbs', [
            {to: `/purchase/${props.from}/${props.till}`, label: 'Inkopen'},
            {label: locationModel.name}
        ]);
    })

    /*
    |--------------------------------------------------------------------------
    | Create / Store
    |--------------------------------------------------------------------------
    */
    import CreateProduct from '../../components/create-product/create-product.vue';
    const create = ref(false);
    const submit = async function(form) {
        await purchaseService.create(form.data());
        getPurchases();
        useToast().success('Inkoop opgeslagen');
    }

    /*
    |--------------------------------------------------------------------------
    | Edit / Update
    |--------------------------------------------------------------------------
    */
    const editModal = ref(false);
    const openEditModal = function(purchase) {
        editModal.value = purchase;
        overviewModal.value = false;
    }
    const update = function(form) {
        return purchaseService.edit(form.id, form.data()).then(() => {
            editModal.value = false;
            getPurchases();
            useToast().success('Inkoop gewijzigd');
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Delete
    |--------------------------------------------------------------------------
    */
    const deletePurchase = function() {
        purchaseService.delete(removeModal.value.id).then(() => {
            removeModal.value = false;
            getPurchases();
            useToast().success('Inkoop verwijderd');
        });
    }

    const removeModal = ref(false);
    const openRemoveModal = function(purchase) {
        removeModal.value = purchase;
        overviewModal.value = false;
    }


    /*
    |--------------------------------------------------------------------------
    | Headers
    |--------------------------------------------------------------------------
    */
    const headers = computed(() =>
        purchases.value
        .unique(purchase => purchase.typeId + purchase.sizeId)
        .map(purchase => ({
            type: purchase.type,
            size: purchase.size,
            contents: purchases.value.filter(p =>
                p.typeId === purchase.typeId
                && p.sizeId === purchase.sizeId
            )
                .unique('contentId').sortBy('contentId')
                .pluck('content')
        })).sort(sortPurchases)
    );
</script>
